<!--
 * @Author: PoJun
 * @Date: 2023-03-07 16:07:48
 * @LastEditors: PoJun
 * @LastEditTime: 2023-08-17 11:42:07
 * @Message: Nothing
-->
<template>
    <div id="appContent">
        <!-- header intro -->
        <div class="header-intro" :class="[scrollTop >= 66 ? 'showHead' : '']">
            <div class="navbar navbar-expand-md">
                <div class="container">
                    <div class="navbar-brand">
                        <img src="../assets/img/logo.png" alt="" />
                        <h2>诚唐科技</h2>
                    </div>
                    <button
                        class="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <i class="fas fa-align-justify"></i>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <router-link class="nav-link" to="/home">首页</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/product">项目展示</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/about">关于我们</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link" to="/contact">联系我们</router-link>
                            </li>
                            <!-- <li class="nav-item">
                                <router-link class="nav-link" to="/faq">问题解答</router-link>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- end header intro -->
        <router-view></router-view>
        <!-- footer -->
        <footer>
            <div class="container">
                <h2>诚唐科技有限责任公司</h2>
                <p>
                    感谢您访问诚唐科技官网，如果您有任何软件开发或技术咨询的需求，请随时与我们联系。我们期待与您建立长期合作伙伴关系，共同创造更加美好的未来。
                </p>
                <div>
                    <span data-v-2c2a68eb="">备案号：</span>
                    <a href="https://beian.miit.gov.cn/" style="color: #fff" target="_blank">
                        蜀ICP备15008980号-1
                    </a>
                </div>
            </div>
        </footer>
        <!-- end footer -->
    </div>
</template>

<script>
export default {
    name: "layout",
    data() {
        return {
            scrollTop: 0,
        };
    },
    methods: {
        scrollToTop() {
            this.scrollTop =
                window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        },
    },
    mounted() {
        window.addEventListener("scroll", this.scrollToTop);
    },
};
</script>

<style scoped>
#appContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.header-intro {
    width: 100vw;
    top: 0;
    z-index: 2;
    position: static;
}

.header-intro.showHead {
    position: fixed;
    background: #fff;
    animation: showheader 0.7s;
}
@keyframes showheader {
    0% {
        top: -100px;
    }
    100% {
        top: 0px;
    }
}
.header-intro .navbar-brand {
    display: flex;
    align-items: center;
}
.header-intro .navbar-brand img {
    width: 40px;
    height: 40px;
}

.header-intro .navbar-brand h2 {
    margin-bottom: 0;
    margin-left: 15px;
}

.router-link-active {
    color: #614caa;
    font-weight: bold;
}
</style>
